import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { formatDateWordsWithYear, formatTime } from '../../helpers/date';
import { ADD_EVALUATION_COMMENT, GET_COMPANY_EVALUATIONS, GET_COMPANY_ORDERING_EVALUATIONS } from '../../graphql/customers';

const RiskEvaluationNotesComponent = ({ actions, company, receiver }) => {
  const [companyTab, setCompanyTab] = useState(0);
  const manageMasterEntity1 = () => {
    if (companyTab === 1) return receiver.id;
    return company.id;
  };
  const manageMasterEntity2 = () => {
    if (companyTab === 2) return receiver.id;
    return null;
  };
  const [text, setText] = useState('');
  const [showAddComment, setShowAddComment] = useState(false);

  const [addCompanyComment, { loading: addingComment }] = useMutation(
    ADD_EVALUATION_COMMENT,
    {
      variables: {
        comment: text,
        masterEntity1: manageMasterEntity1(),
        masterEntity2: manageMasterEntity2(),
      },
      refetchQueries: [GET_COMPANY_EVALUATIONS, GET_COMPANY_ORDERING_EVALUATIONS],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setText('');
        setShowAddComment(false);
      },
    },
  );
  const retrieveSubtitle = () => {
    if (companyTab === 0) return company.name;
    if (companyTab === 1) return receiver.name;
    return `${company.name} - ${receiver.name}`;
  };
  const retrieveCompanyComments = () => {
    if (companyTab === 0) return actions.filter((row) => row.masterEntity?.id === company.id);
    if (companyTab === 1) return actions.filter((row) => row.masterEntity?.id === receiver.id);
    return actions.filter(
      (row) => row.masterEntity1?.id === company.id && row.masterEntity2?.id === receiver.id,
    );
  };
  return (
    <>
      <Typography variant="h2" color="primary">
        {retrieveSubtitle()}
      </Typography>
      <List>
        <ListItem sx={{ marginBottom: 1 }}>
          <Tabs
            centered
            variant="fullWidth"
            value={companyTab}
            onChange={(_, value) => {
              setCompanyTab(value);
            }}
          >
            <Tab label="Cliente" value={0} />
            <Tab label="Deudor" value={1} />
            <Tab label="Relación" value={2} />
          </Tabs>
        </ListItem>
        <Divider />
        {retrieveCompanyComments().map((comment) => (
          <>
            <ListItem sx={{ marginBottom: 0.5 }}>
              <Typography variant="h5" color="primary">
                {comment.user.firstName} {comment.user.lastName}
              </Typography>
            </ListItem>
            <ListItem sx={{ marginBottom: 0.5 }}>
              <Typography variant="body1" whiteSpace="pre-line">
                {comment.comments}
              </Typography>
            </ListItem>
            <ListItem sx={{ marginBottom: 0.5 }}>
              <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                {`${formatDateWordsWithYear(moment(comment.createdAt))} a 
                las ${formatTime(moment(comment.createdAt))}`}
              </Typography>
            </ListItem>
            <Divider />
          </>
        ))}
        {!showAddComment ? (
          <ListItem sx={{ marginTop: 1 }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => { setShowAddComment(true); }}
            >
              Agregar Comentario
            </Button>
          </ListItem>
        ) : (
          <>
            <ListItem>
              <TextField
                sx={{ width: '100%' }}
                variant="outlined"
                name="rut"
                value={text}
                onChange={(e) => setText(e.target.value)}
                multiline
                rows={3}
              />
            </ListItem>
            <ListItem sx={{ marginLeft: '25%' }}>
              <LoadingButton
                sx={{ marginRight: '1%' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={addCompanyComment}
                disabled={!text}
                loading={addingComment}
              >
                Guardar
              </LoadingButton>
              <Button
                sx={{ marginLeft: '1%' }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => { setShowAddComment(false); }}
              >
                Cancelar
              </Button>
            </ListItem>
          </>
        )}
      </List>
    </>
  );
};

RiskEvaluationNotesComponent.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  receiver: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default RiskEvaluationNotesComponent;
