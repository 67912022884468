import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import { useInvoicePreColumns } from '../../constants';
import { INVOICES_HISTORY_EVALUATIONS, SIMPLE_INVOICE } from '../../graphql';
import { useGetInvoice } from '../../hooks';
import { FingoDataGrid } from '../dataGrids';
import FingoTabs from '../tabs/FingoTabs';

const HistoryList = ({ companyId, receiverId }) => {
  const numberOfPastEvaluations = 10;
  const { data, loading } = useQuery(INVOICES_HISTORY_EVALUATIONS, {
    variables: {
      preoffer_Preofferevaluationrequest_Status_In: ['Offered', 'Rejected'],
      orderBy: '-preoffer_Preofferevaluationrequest_Id',
      first: numberOfPastEvaluations,
      companyId,
      receiverId,
    },
  });
  const rows = data?.invoices.edges.map((edge) => edge.node) || [];
  const columns = useInvoicePreColumns();
  const includeHeaders = [
    'dateIssued',
    'preoffer_Preofferevaluationrequest_Status',
    'preoffer_Preofferevaluationrequest_RejectionReasonLabel',
    'preoffer_Preofferevaluationrequest_Ratification',
    'preoffer_Preofferevaluationrequest_AssignedEvaluator_FirstName',
  ];
  if (companyId) {
    includeHeaders.unshift('receiver_Name');
  }
  if (receiverId) {
    includeHeaders.unshift('company_Name');
  }
  return (
    <Box height="100%" width="100%">
      <FingoDataGrid
        rows={rows}
        includeHeaders={includeHeaders}
        columns={columns}
        loadingWithSkeleton={loading}
        serverFilters={false}
        rowCount={numberOfPastEvaluations}
        page={0}
        pageSize={numberOfPastEvaluations}
        hideFooter
      />
    </Box>
  );
};

HistoryList.propTypes = {
  companyId: PropTypes.string,
  receiverId: PropTypes.string,
};
HistoryList.defaultProps = {
  companyId: null,
  receiverId: null,
};

const RiskEvaluationPastEvaluationComponent = ({ invoiceId }) => {
  const invoice = useGetInvoice(invoiceId, SIMPLE_INVOICE);
  if (!invoice) {
    return (
      <Box minHeight={250} width="100%" textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  const companyId = invoice.company.id;
  const receiverId = invoice.receiver.id;
  return (
    <FingoTabs
      tabInfo={[
        {
          tabName: 'Cliente',
          component: HistoryList,
          componentProps: { companyId },
        },
        {
          tabName: 'Deudor',
          component: HistoryList,
          componentProps: { receiverId },
        },
        {
          tabName: 'Relación',
          component: HistoryList,
          componentProps: { companyId, receiverId },
        },
      ]}
    />
  );
};

RiskEvaluationPastEvaluationComponent.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default RiskEvaluationPastEvaluationComponent;
