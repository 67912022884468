import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React from 'react';
import { useOrderingLucilaPreColumns } from '../../constants';
import { HISTORIC_PURCHASE_ORDER_EVALUATION } from '../../graphql';
import useGetPurchaseOrder from '../../hooks/useGetPurchaseOrder';
import { FingoDataGrid } from '../dataGrids';
import FingoTabs from '../tabs/FingoTabs';

const HistoryList = ({ companyId }) => {
  const numberOfPastEvaluations = 10;
  const { data, loading } = useQuery(HISTORIC_PURCHASE_ORDER_EVALUATION, {
    variables: {
      orderBy: '-orderingsimulation_EvaluationRequest_Id',
      first: numberOfPastEvaluations,
      companyId,
      status_In: [
        'Offered',
        'Rejected',
        'PendingAssignmentDocument',
        'PendingSignature',
        'PendingTransfer',
        'PendingCollection',
        'PendingInvoices',
        'ProcessingInvoices',
        'Factoring',
        'Finished',
        'Expired',
      ],
    },
  });
  const rows = data?.purchaseOrders.edges.map((edge) => edge.node) || [];
  const columns = useOrderingLucilaPreColumns();
  const includeHeaders = [
    'orderNumber',
    'purchaser_Name',
    'orderingoffer_OrderingFinancedAmount',
    'totalAmount',
    'status',
    'orderingsimulation_EvaluationRequest_evaluationAnalystDescription',
  ];
  return (
    <Box height="100%" width="100%">
      <FingoDataGrid
        rows={rows}
        includeHeaders={includeHeaders}
        columns={columns}
        loadingWithSkeleton={loading}
        serverFilters={false}
        rowCount={numberOfPastEvaluations}
        page={0}
        pageSize={numberOfPastEvaluations}
        hideFooter
      />
    </Box>
  );
};

HistoryList.propTypes = {
  companyId: PropTypes.string,
};
HistoryList.defaultProps = {
  companyId: null,
};

const RiskEvaluationPastEvaluationComponentOrdering = ({ purchaseOrderId }) => {
  const purchaseOrder = useGetPurchaseOrder(purchaseOrderId);
  if (!purchaseOrder) {
    return (
      <Box minHeight={250} width="100%" textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  const companyId = purchaseOrder.company.id;
  return (
    <FingoTabs
      tabInfo={[
        {
          tabName: 'Cliente',
          component: HistoryList,
          componentProps: { companyId },
        },
      ]}
    />
  );
};

RiskEvaluationPastEvaluationComponentOrdering.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
};

export default RiskEvaluationPastEvaluationComponentOrdering;
