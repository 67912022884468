import React from 'react';
import PropTypes from 'prop-types';
import CreateIcon from '@mui/icons-material/Create';
import DrawerHeader from './DrawerHeader';
import { ActionDrawerCell } from '../cells';
import RiskEvaluationNotesComponent from './RiskEvaluationNotesComponent';
import EvaluationCommentPropType from '../../propTypes/EvaluationComments';

const RiskEvaluationNotesActions = ({ document }) => {
  const receiver = document.receiver || document.purchaser;
  const companyComments = document.company.masterEntity.evaluationComments;
  const receiverComments = receiver.evaluationComments;
  const companyReceiverComments = document.companyReceiverEvaluationComment;
  const actions = companyComments.concat(receiverComments).concat(companyReceiverComments);
  return (
    <ActionDrawerCell
      title="Mis Notas"
      actions={actions}
      headerComponent={DrawerHeader}
      contentComponent={RiskEvaluationNotesComponent}
      icon={CreateIcon}
      company={document.company}
      receiver={document.receiver || document.purchaser}
    />
  );
};

RiskEvaluationNotesActions.propTypes = {
  document: PropTypes.shape({
    companyReceiverEvaluationComment: PropTypes.arrayOf(EvaluationCommentPropType).isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      masterEntity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        evaluationComments: PropTypes.arrayOf(EvaluationCommentPropType).isRequired,
      }),
    }).isRequired,
    receiver: PropTypes.shape({
      id: PropTypes.string.isRequired,
      evaluationComments: PropTypes.arrayOf(EvaluationCommentPropType).isRequired,
    }),
    purchaser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      evaluationComments: PropTypes.arrayOf(EvaluationCommentPropType).isRequired,
    }),
  }).isRequired,
};

export default RiskEvaluationNotesActions;
