import React from 'react';
import Divider from '@mui/material/Divider';

const FingoDivider = () => (
  <Divider
    orientation="horizontal"
    sx={{ borderColor: 'primary.main', borderBottomWidth: 2, width: '100%', borderRadius: 2 }}
  />
);

export default FingoDivider;
