import React from 'react';
import PropTypes from 'prop-types';
import TimelineIcon from '@mui/icons-material/Timeline';
import DrawerHeader from './DrawerHeader';
import { ActionDrawerCell } from '../cells';
import RiskEvaluationPastEvaluationComponent from './RiskEvaluationPastEvaluationContent';

const RiskEvaluationPastEvaluation = ({ invoiceId }) => (
  <ActionDrawerCell
    title="Evaluaciones pasadas"
    actions={[]}
    headerComponent={DrawerHeader}
    contentComponent={RiskEvaluationPastEvaluationComponent}
    icon={TimelineIcon}
    width="70%"
    invoiceId={invoiceId}
  />
);

RiskEvaluationPastEvaluation.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default RiskEvaluationPastEvaluation;
