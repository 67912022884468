import React from 'react';
import PropTypes from 'prop-types';
import TimelineIcon from '@mui/icons-material/Timeline';
import DrawerHeader from './DrawerHeader';
import { ActionDrawerCell } from '../cells';
import RiskEvaluationPastEvaluationComponentOrdering from './RiskEvaluationPastEvaluationContentOrdering';

const RiskEvaluationPastEvaluationOrdering = ({ purchaseOrderId }) => (
  <ActionDrawerCell
    title="Evaluaciones pasadas"
    actions={[]}
    headerComponent={DrawerHeader}
    contentComponent={RiskEvaluationPastEvaluationComponentOrdering}
    icon={TimelineIcon}
    width="70%"
    purchaseOrderId={purchaseOrderId}
  />
);

RiskEvaluationPastEvaluationOrdering.propTypes = {
  purchaseOrderId: PropTypes.string.isRequired,
};

export default RiskEvaluationPastEvaluationOrdering;
