import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MobileStepper from '@mui/material/MobileStepper';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { MasterEntityType, ArrayOfId } from '../../propTypes';
import FingoDialog from './FingoDialog';
import SelectRadioGroup from '../selects/SelectRadioGroup';
import InvoicesAttachableFiles from '../attach/InvoicesAttachableFiles';
import { ContactList } from '../lists';

const OperativeStepperDialog = ({
  open,
  onClose,
  selectedOption,
  selectedDocuments,
  firstStepOptions,
  managersAndAttachs,
  currentStep,
  documentType,
  setOptions,
  setCurrentStep,
  selectedContactsIds,
  setSelectedContactsIds,
  setManagersAndAttachs,
  masterEntityTarget,
  operationType,
  showAllContactTypes,
  SecondStepComponent,
  lastStepComponent,
  onSubmit,
  submitButtonText,
  submitButtonId,
  loadingSubmit,
  stepToTitle,
  obligatoryContactId,
  paperProps,
}) => {
  const innerOnClose = useCallback(() => {
    setCurrentStep(0);
    onClose();
  }, []);
  return (
    <FingoDialog
      open={open}
      handleClose={innerOnClose}
      title={stepToTitle[currentStep]}
      fullWidth
      PaperProps={{ sx: { borderRadius: 4, maxWidth: 770, ...paperProps } }}
      dialogActionButton={(
        <MobileStepper
          steps={3}
          variant="dots"
          position="static"
          activeStep={currentStep}
          sx={{ width: '100%' }}
          nextButton={currentStep === 2 ? (
            <LoadingButton
              id={submitButtonId}
              size="small"
              onClick={onSubmit}
              loading={loadingSubmit}
              disabled={false}
              variant="contained"
              color="primary"
            >
              {submitButtonText}
            </LoadingButton>
          ) : (
            <Button
              size="small"
              onClick={() => setCurrentStep((prevActiveStep) => prevActiveStep + 1)}
              disabled={
                (currentStep === 0 && !selectedOption)
                || (currentStep === 1 && selectedOption !== 'OTHER' && selectedContactsIds.length === 0 && obligatoryContactId)
              }
            >
              Siguiente
              <KeyboardArrowRight />
            </Button>
          )}
          backButton={(
            <Button
              size="small"
              onClick={() => setCurrentStep((prevActiveStep) => prevActiveStep - 1)}
              disabled={currentStep === 0}
            >
              <KeyboardArrowLeft />
              Atrás
            </Button>
          )}
        />
      )}
    >
      {currentStep === 0 && (
        <Grid container direction="row" justifyContent="left">
          <Grid item xs={5}>
            <SelectRadioGroup
              options={firstStepOptions}
              setOption={setOptions}
              selectedOption={selectedOption}
            />
          </Grid>
          <Divider orientation="vertical" flexItem />
          {documentType === 'invoice' && (
            <Grid item marginLeft={3} xs={5}>
              <InvoicesAttachableFiles
                documents={selectedDocuments}
                managersAndAttachs={managersAndAttachs}
                setManagersAndAttachs={setManagersAndAttachs}
              />
            </Grid>
          )}
        </Grid>
      )}
      {currentStep === 1 && (
        <SecondStepComponent
          masterEntity={masterEntityTarget}
          contactType={operationType}
          showAllContactTypes
          selectedContactIds={selectedContactsIds}
          setSelectedContactIds={setSelectedContactsIds}
        />
      )}
      {currentStep === 2 && (
        lastStepComponent
      )}
    </FingoDialog>
  );
};

OperativeStepperDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  firstStepOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  })),
  setOptions: PropTypes.func.isRequired,
  selectedOption: PropTypes.string.isRequired,
  selectedDocuments: ArrayOfId,
  managersAndAttachs: PropTypes.arrayOf(
    PropTypes.shape({
      managerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  masterEntityTarget: MasterEntityType,
  operationType: PropTypes.string,
  setSelectedContactsIds: PropTypes.func,
  setCurrentStep: PropTypes.func.isRequired,
  setManagersAndAttachs: PropTypes.func,
  currentStep: PropTypes.number.isRequired,
  documentType: PropTypes.string,
  selectedContactsIds: ArrayOfId,
  onSubmit: PropTypes.func.isRequired,
  SecondStepComponent: PropTypes.func,
  lastStepComponent: PropTypes.element.isRequired,
  loadingSubmit: PropTypes.bool,
  showAllContactTypes: PropTypes.bool,
  submitButtonText: PropTypes.string,
  submitButtonId: PropTypes.string,
  stepToTitle: PropTypes.shape({
    0: PropTypes.string,
    1: PropTypes.string,
    2: PropTypes.string,
  }),
  obligatoryContactId: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  paperProps: PropTypes.object,
};

OperativeStepperDialog.defaultProps = {
  operationType: '',
  firstStepOptions: [],
  loadingSubmit: false,
  stepToTitle: {
    0: 'Paso 1',
    1: 'Paso 2',
    2: 'Paso 3',
  },
  submitButtonText: 'Aceptar',
  submitButtonId: 'submit-collection',
  documentType: '',
  showAllContactTypes: false,
  SecondStepComponent: ContactList,
  selectedContactsIds: [],
  setSelectedContactsIds: () => {},
  masterEntityTarget: null,
  obligatoryContactId: true,
  managersAndAttachs: [],
  setManagersAndAttachs: () => {},
  selectedDocuments: [],
  paperProps: {},
};

export default OperativeStepperDialog;
